import type { IconProps } from './types';

export const IconArrowLeft = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M500 274.477H99.1071L240.357 418.242L210.357 464.082L0 250.041L210.357 36L240.357 81.8405L99.1071 225.605H500V274.477Z'
        fill={color}
      />
    </svg>
  );
};
