import Image from 'next/image';

export const IconLogo = ({
  width,
  height,
  color,
  className,
}: {
  width: number;
  height: number;
  color: 'black' | 'white';
  className?: string;
}) => {
  return (
    <Image
      src={`/images/ui/icons/logo_${color}.svg`}
      width={width}
      height={height}
      alt='KLEW'
      className={className}
      priority
    />
  );
};
