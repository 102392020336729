'use client';

import clsx from 'clsx';
import { SelectIcon } from './SelectIcon';
import type {
  ButtonColor,
  IconComponentType,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from './types';
import { cn } from '@/utils/cn';

export type ButtonProps = {
  label?: string;
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  fullWidth?: boolean;
  IconComponent?: IconComponentType;
  disabled?: boolean;
  type?: ButtonType;
  className?: string;
} & React.HTMLAttributes<HTMLButtonElement>;

export const Button = ({
  label,
  variant = 'default',
  color = 'black',
  size = 'lg',
  fullWidth = false,
  IconComponent,
  disabled = false,
  type = 'button',
  className = '',
  onClick,
  ...props
}: ButtonProps) => {
  const isBare = variant === 'bare';
  const isSmall = size === 'sm';
  const isWhite = color.match(/^#?(ffffff|fff|white)$/i);
  const isBlack = color.match(/^#?(000000|000|black)$/i);
  const isCustomColor = !(color === 'gradient' || color === 'white' || color === 'black');

  const sizes = {
    default: isSmall ? 'px-5 py-2' : 'px-5 py-2 min-w-[16.4rem] h-12',
    outline: isSmall ? 'px-5 py-2' : 'px-5 py-2 min-w-[16.4rem] h-12',
    withIcon: isSmall ? 'px-5 py-2' : 'px-[3rem] py-3',
    bare: isSmall ? 'size-3' : 'size-8',
    iconOnly: '',
  };

  const fontStyle = isSmall ? 'text-button-12' : 'text-button-14';

  const disabledStyle = isBlack
    ? 'bg-black opacity-10 text-white'
    : 'bg-white opacity-30 text-black';

  let baseStyle = `rounded-full ${sizes[variant]}`;
  if (variant === 'withIcon') {
    baseStyle += ' flex gap-3 items-center';
  }

  const borderStyle = 'border-solid border-[0.1rem]';

  const variantStyle = {
    default: cn({
      [`bg-${color}`]: color !== 'gradient',
      'border-white': isCustomColor && isBlack,
      'text-black': isWhite,
      'text-white': !isWhite,
    }),
    outline: cn(`${borderStyle}`, {
      'border-white text-white': isWhite,
      'border-black text-black': !isWhite,
    }),
    withIcon: cn({
      [`bg-${color}`]: !isCustomColor,
      'text-black': isWhite,
      'text-white': !isWhite,
    }),
    bare: isSmall ? borderStyle : `bg-${color}`,
    iconOnly: '',
  }[variant];

  const colorStyle = {
    gradient: 'bg-kl-gradient text-white',
    white: isBare && isSmall ? 'border-white' : '',
    black: isBare && isSmall ? 'border-black' : '',
  }[color];

  const finalStyle = clsx(
    baseStyle,
    disabled && !(isBare && isSmall) ? disabledStyle : variantStyle,
    colorStyle,
    fontStyle,
    { 'w-full': fullWidth },
    className,
  );

  return (
    <button
      type={type}
      onClick={onClick}
      className={finalStyle}
      style={disabled || !isCustomColor ? {} : { backgroundColor: color }}
      disabled={disabled}
      {...props}
    >
      {IconComponent && (
        <SelectIcon variant={variant} color={color} size={size} IconComponent={IconComponent} />
      )}
      {label && <span>{label}</span>}
    </button>
  );
};
