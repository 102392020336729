import clsx from 'clsx';
import React from 'react';
import { cn } from '@/utils/cn';

type Props = {
  fullscreen?: boolean;
  text?: string;
  className?: string;
  zIndex?: number;
  dark?: boolean;
  transparentBg?: boolean;
  /** PCレイアウトの左ナビエリアも含めて全画面にローディングを表示する場合に`true` */
  coverLeftNav?: boolean;
};

const SPINNER_BARS = new Array(8).fill({});
const ROTATE_LIST = [0, 45, 90, 135, 180, 225, 270, 315];
const ANIMATION_DELAY_LIST = [-0.7, -0.6, -0.5, -0.4, -0.3, -0.2, -0.1, 0];

export const Loading: React.FC<Props> = (props) => {
  const { fullscreen = true, coverLeftNav = false } = props;

  if (fullscreen) {
    return (
      <div className='fixed left-0 top-0 flex h-lvh w-full justify-center'>
        <div className='absolute flex size-full md:relative md:w-md-base lg:w-lg-base'>
          <div className={cn('shrink-0 md:hidden lg:w-[20rem]', !coverLeftNav && 'lg:block')} />
          <LoadingInner {...props} />
        </div>
      </div>
    );
  }

  return <LoadingInner {...props} />;
};

export const LoadingInner: React.FC<Props> = ({
  fullscreen = true,
  text,
  className,
  zIndex = fullscreen ? 100 : 0,
  dark = false,
  transparentBg = false,
}) => {
  return (
    <div
      style={{ zIndex }}
      className={clsx(
        fullscreen ? 'absolute left-0 top-0 size-full md:relative' : 'scale-[0.583]',
        'flex flex-col items-center justify-center gap-[0.7rem] bg-opacity-80',
        className,
        dark ? 'bg-black' : 'bg-white',
        transparentBg && 'bg-opacity-[0]',
      )}
    >
      <div className='relative box-border inline-block size-[48px]'>
        {SPINNER_BARS.map((_, index) => {
          return (
            <div
              key={`spinner-bar-${index}`}
              className={clsx(
                'box-border origin-[2.4rem_2.4rem] animate-[spinner_0.96s_linear_infinite]',
                'after:absolute after:left-[2.35rem] after:top-0 after:box-border after:block after:h-[1.4rem] after:w-[0.2rem] after:origin-center after:rounded-[1rem] after:bg-black after:content-[""]',
                dark && ' after:bg-white',
              )}
              style={{
                transform: `rotate(${ROTATE_LIST[index]}deg)`,
                animationDelay: `${ANIMATION_DELAY_LIST[index] * 1.2}s`,
              }}
            />
          );
        })}
      </div>
      {text && <p className={clsx(dark && 'text-white')}>{text}</p>}
    </div>
  );
};
