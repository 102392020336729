import type { ButtonColor, IconComponentType, ButtonSize, ButtonVariant } from './types';

export const SelectIcon = ({
  variant,
  color,
  size,
  IconComponent,
}: {
  variant: ButtonVariant;
  color: ButtonColor;
  size: ButtonSize;
  IconComponent: IconComponentType;
}) => {
  const iconSizes: { [name: string]: string } = {};
  if (variant === 'iconOnly') {
    iconSizes['sm'] = '';
    iconSizes['lg'] = '';
  } else if (variant === 'bare') {
    iconSizes['sm'] = 'size-1.5';
    iconSizes['lg'] = 'size-3.5';
  } else {
    iconSizes['sm'] = 'size-3';
    iconSizes['lg'] = 'size-5';
  }

  let iconColor;
  if (variant === 'iconOnly') {
    iconColor = color;
  } else if (variant === 'bare' && size === 'sm') {
    iconColor = color === 'white' ? 'white' : 'black';
  } else {
    iconColor = color === 'white' ? 'black' : 'white';
  }

  return (
    <span className={`block ${variant === 'bare' ? 'mx-auto' : ''} ${iconSizes[size]}`}>
      <IconComponent color={iconColor} />
    </span>
  );
};
